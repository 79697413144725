import { useEffect, useState } from 'react';

interface Log {
    timestamp: number;
    message: string;
    severity: Severity;
}

enum Severity {
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error'
}

const ServiceWorkerManager = () => {
    const [serviceWorkerUrl, setServiceWorkerUrl] = useState<string>('');
    const [logs, setLogs] = useState<Log[]>([]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const swUrl = `${window.location.protocol}//${window.location.host}/sw.js`;
            setServiceWorkerUrl(swUrl);
        }

        listenToServiceWorker();
    }, []);

    async function listenToServiceWorker() {
        updateLogs('Setting up message listener.');
        navigator.serviceWorker.addEventListener('message', async event => {
            console.log('Event received', { event });
            if (event.data.meta === 'workbox-broadcast-update') {
                const { cacheName, updatedURL } = event.data.payload;
                updateLogs(JSON.stringify(event.data.payload));
            }
        });
    }

    function updateLogs(message: string, severity: Severity = Severity.INFO) {
        setLogs(prevLogs => [
            { timestamp: Date.now(), message, severity },
            ...prevLogs
        ]);
    }

    function clearLogs() {
        setLogs([]);
    }

    async function checkServiceWorker() {
        if (navigator.serviceWorker) {
            try {
                const registrations =
                    await navigator.serviceWorker.getRegistrations();

                if (registrations.length === 0) {
                    updateLogs('No service worker found.');
                    return;
                }

                updateLogs(
                    `Service worker information: ${registrations.map(
                        ({ scope, active, waiting, installing }) => {
                            return JSON.stringify({
                                scope,
                                'active.scriptURL': active?.scriptURL,
                                'active.state': active?.state,
                                waiting,
                                installing
                            });
                        }
                    )}`
                );
            } catch (error) {
                updateLogs(
                    `An error occurred while checking service worker registration: ${error}`
                );
            }
        }
    }

    async function registerServiceWorker() {
        if (navigator.serviceWorker) {
            try {
                const registrations =
                    await navigator.serviceWorker.getRegistrations();

                let alreadyRegistered = false;
                if (registrations.length > 0) {
                    registrations.forEach(registration => {
                        if (registration.active) {
                            if (
                                registration.active.scriptURL ===
                                serviceWorkerUrl
                            ) {
                                alreadyRegistered = true;
                                updateLogs(
                                    'Service worker is already registered.'
                                );
                                return;
                            }
                        }
                    });
                }

                if (!alreadyRegistered) {
                    await navigator.serviceWorker.register(serviceWorkerUrl);
                    updateLogs(
                        `Service worker registered at ${serviceWorkerUrl}.`
                    );

                    if (window.location.hostname === 'localhost') {
                        updateLogs(
                            `Installing a service worker in development mode is not supported. The application will crash. Use the "Unregister Service Worker" button or use your browser development tools to kill the service worker once you are done here.`,
                            Severity.WARN
                        );
                    }
                }
            } catch (error) {
                updateLogs(
                    `An error occurred while registering service worker: ${error}`
                );
            }
        }
    }

    async function unregisterServiceWorker() {
        if (navigator.serviceWorker) {
            const registrations =
                await navigator.serviceWorker.getRegistrations();

            if (registrations.length === 0) {
                updateLogs('No service worker currently registered.');
            }

            for (let index in registrations) {
                registrations[index].unregister();
                const message = `Service worker at ${registrations[index].active?.scriptURL} unregistered.`;
                updateLogs(message);
            }
        }
    }

    async function getStorageQuota() {
        function byteToMegaByte(quantity: number) {
            return Math.ceil(quantity / 1000 / 1000);
        }

        if (navigator.storage && navigator.storage.estimate) {
            const quota = await navigator.storage.estimate();
            if (
                quota &&
                quota.usage !== undefined &&
                quota.quota !== undefined
            ) {
                const percentageUsed = (quota.usage / quota.quota) * 100;
                const remaining = quota.quota - quota.usage;
                return updateLogs(
                    `Used: ${byteToMegaByte(quota.usage)} MB (${Math.ceil(
                        percentageUsed
                    )}%). Remaining: ${byteToMegaByte(
                        remaining
                    )} MB. Quota: ${byteToMegaByte(quota.quota)} MB. `
                );
            }

            return updateLogs(
                'StorageManager API does not provide data for quota estimate.',
                Severity.WARN
            );
        }

        return updateLogs(
            'StorageManager API seems unavailable.',
            Severity.ERROR
        );
    }

    return (
        <>
            <div>
                <button onClick={checkServiceWorker}>
                    Check Service Workers
                </button>
                <button onClick={registerServiceWorker}>
                    Register Service Worker
                </button>
                <button onClick={unregisterServiceWorker}>
                    Unregister Service Worker
                </button>
            </div>
            <div>
                <button onClick={getStorageQuota}>
                    Check Storage API Quota
                </button>
                <button onClick={() => window.location.reload()}>
                    Reload page
                </button>
                <button onClick={clearLogs}>Clear Logs</button>
            </div>
            <div>
                {logs.map(log => (
                    <div
                        key={log.timestamp}
                        style={
                            (log.severity === Severity.WARN && {
                                color: 'red'
                            }) ||
                            undefined
                        }
                    >
                        <code
                            style={{
                                overflowWrap: 'break-word'
                            }}
                        >
                            {new Date(log.timestamp).toLocaleString()}:{' '}
                            {log.message}
                        </code>
                    </div>
                ))}
            </div>
        </>
    );
};

export default ServiceWorkerManager;
